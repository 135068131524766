import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import PageIntroduction from '../components/PageIntroduction';
import { jobCodes } from '../util/data/jobCodes';
import { H4, H3, Ul, Reference, Body } from '../util/typography';
import * as colors from '../util/colors';
import styled from 'styled-components';
import RelatedContent from '../components/RelatedContent';
import { respondTo } from '../util/breakpoints';
import mobileChart from '../assets/content-icons/pt-moa-graphic-mobile.png';
import desktopChart from '../assets/content-icons/pt-moa-graphic-desktop.png';
import Subsection from '../components/Subsection';
import Chart from '../components/Chart';
import Section from '../components/Section';
import PrimaryCallout from '../components/PrimaryCallout';
// import { border } from 'polished';
// import Dosing2Column from '../components/Dosing2Column';
import dosingImg from '../assets/dosing-graphics/lenvima-dose-reduction-mobile.png';
import dosingImgDesktop from '../assets/dosing-graphics/lenvima-dose-reduction-desktop.png';
import Dosing2ColumnText from '../components/Dosing2ColumnText';

// SEO INFORMATION
const pageMeta = {
    title: `KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Mechanisms of Action`,
    keywords: `keytruda and lenvima, mechanisms of action`,
    description: `Health care professionals can review the mechanisms of action for KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib). See how KEYTRUDA and LENVIMA may work.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/mechanisms-of-action/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/mechanisms-of-action/","@type":"MedicalWebPage","name":"Mechanisms of Action","description":"KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib): Target distinct pathways","":"Merck"}`
    ]
}


const jobCode = jobCodes[0].jobCode;

const MechanismsOfAction = ({location}) => {

    const RCLinks = [
        {
            eyebrow: 'Advanced Renal Cell Carcinoma',
            title: 'See the clinical data and treatment recommendations',
            to: '/advanced-renal-cell-carcinoma/'
        },
        {
            eyebrow: 'Advanced Endometrial Carcinoma',
            title: 'See the clinical data and treatment recommendations',
            to: '/endometrial-carcinoma/'
        },
    ];

    const BreakForSmallerDevices = styled.span`
    text-wrap: wrap;
    white-space: wrap;
    @media (min-width: 360px) {
        text-wrap: nowrap;
        white-space: nowrap;
    }
`;

const StudyDesignSection = styled(Section)`
    padding-bottom: 0px;
    > div > div:first-child {
        padding-top:0;
        padding-bottom: 0;
        ${respondTo.md`
            padding-bottom: 0px;
        `}
    }
`;
// const Chart = styled(Section)`
//     border: 0;
// `;
const RccFootnote = styled(Reference)`
    p {
        width: 100%;
        display: block;
    }
    sup {
        display: flex;
        flex: 0 0 6px;
        justify-content: center;
    }
`;
const SectionIntro = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top:0;
    i {
        letter-spacing: 1.2px;
    }
`;

const RCCEfficacyCallout = styled(PrimaryCallout)`
max-width: 100%;
padding: 40px 20px !important;
text-align: left;
${respondTo.md`
    padding: 40px 50px !important;
    text-align: center;
`}
    [class*='PrimaryCallout__Title-']{
        font-weight: 700;
    }
    &:before {
        border-left: 0px solid ${colors.cloud};
        background: ${colors.cloud};
    }
    &.noBorder {
        padding-left: 0px;
        ${respondTo.md`
            padding-left: 0px;
        `}
    }
    &.noBorder:before {
        content: none;
    }
`;
const StyledSubsection = styled(Subsection)`
padding:0;
> div {
    padding-top:0;
}  
`;

const DesktopChartSubsection = styled(Subsection)`
padding-top: 40px;
${respondTo.md`
    .chartChildren{
        padding: 0px;
    }
`}
`;

const Paragraph = styled.p`
padding-bottom: 35px;
${respondTo.md`
    padding-bottom: 20px;
`}
`;
const DesktopOnly = styled.span`
  display: none;
  ${respondTo.md`
    display: inline;
  `}
`;

const MobileOnly = styled.span`
  .pinchContainer{
    display: none;
  }
    .chartChildren{
        padding: 0px;
    }
  ${respondTo.md`
    display: none;
  `}
`;

const MobileChart = styled(Chart)`
padding: 15px 0px;
`;

const TextNoWrap = styled.span`
    text-wrap: nowrap;
`;

const FootnotesSection = styled(Section)`
    padding-bottom: 30px;
    ${respondTo.md`
        padding-bottom: 40px;
    `}
`;

const FootnotesSubsection = styled(Subsection)`
    word-wrap: break-word;
    ${respondTo.md`
        padding-top: 40px;
    `}
`;

const Dosing2ColumnStyled = styled(Dosing2ColumnText)`

    .keytrudaColor li::before{
        background-color: ${colors.brandGreen};
    }

`;

    return(
        <DefaultLayout location={ location } name='Mechanisms of Action' pageMeta={pageMeta} jobCode={jobCode}>
             <PageIntroduction>
                <H3><keytitle><BreakForSmallerDevices>KEYTRUDA®</BreakForSmallerDevices>&nbsp;</keytitle><b><span style={{color: colors.brandGreen}}>(pembrolizumab)</span> and</b>  <lentitle>LENVIMA®&nbsp;(lenvatinib)</lentitle><b>:</b> <br/> Target distinct pathways<sup>1&ndash;7</sup></H3>
            </PageIntroduction>
            <StudyDesignSection title='' bgColor='' id='study-design'>
                <RCCEfficacyCallout bg='mist'>
                        <div>
                        In syngeneic mouse tumor models, combination treatment of a <TextNoWrap>PD-1</TextNoWrap> blocking antibody and kinase inhibitor lenvatinib decreased tumor-associated macrophages, increased activated cytotoxic <TextNoWrap>T cells</TextNoWrap>, and reduced tumor growth compared to either treatment alone.
                        </div>
                    </RCCEfficacyCallout>
                <SectionIntro>
                    {/* <Subtitle>FOCUS ON STUDY DESIGN</Subtitle> */}
                    {/* <H4><b>KEYNOTE-775/Study 309:</b> A pivotal (N=827), multicenter, open-label, randomized, active-controlled, head-to-head phase 3 trial of <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel in patients with advanced endometrial carcinoma.<sup>1</sup> Among the 827 patients, 697 had pMMR (using the VENTANA MMR RxDx Panel test) or not MSI-H tumors</H4> */}
                </SectionIntro>
                <DesktopOnly>
                    <DesktopChartSubsection>
                        <Chart type='mechanism' imgSrc={mobileChart} desktopImgSrc={desktopChart} noTitle showNote imgAlt='Mechanisms of Action of KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) Tumor Microenvironment'>
                        </Chart>
                    </DesktopChartSubsection>
                </DesktopOnly>
            </StudyDesignSection>
            <Dosing2ColumnStyled imgSrc={dosingImg} desktopImgSrc={dosingImgDesktop} imgAlt='LENVIMA® (lenvatinib) Should Be Taken at the Same Time Each Day and Can Be Taken With or Without Food' title=''>
                <p>Mechanism of action: KEYTRUDA</p>
                <p>KEYTRUDA is a humanized monoclonal antibody that binds to the <TextNoWrap>PD-1</TextNoWrap> receptor, blocking both immune-suppressing ligands, <TextNoWrap>PD-L1</TextNoWrap> and <TextNoWrap>PD-L2</TextNoWrap>, from interacting with <TextNoWrap>PD-1</TextNoWrap> to help restore T-cell response and immune response. <b>Restoring active T-cell response could affect both normal healthy cells and tumor cells.</b></p>
                <div>
                <Ul className="keytrudaColor">
                    <li>Normal immune response: When functioning properly, T cells are activated and attack tumor cells.</li>
                    <li>Tumor evasion and T-cell deactivation: Some tumors can evade the immune system through the <TextNoWrap>PD-1</TextNoWrap> pathway. The <TextNoWrap>PD-L1</TextNoWrap> and <TextNoWrap>PD-L2</TextNoWrap> ligands on tumors can bind with <TextNoWrap>PD-1</TextNoWrap> receptors on T cells to inactivate the T cells.</li>
                    <li>T-cell reactivation with KEYTRUDA: KEYTRUDA binds to the <TextNoWrap>PD-1</TextNoWrap> receptor and blocks its interaction with <TextNoWrap>PD-L1</TextNoWrap> and <TextNoWrap>PD-L2</TextNoWrap>, which helps restore the immune response. <b>While having an effect on the tumor, this could also affect normal healthy cells.</b></li>
                </Ul>
                <MobileOnly>
                    <MobileChart type='mechanism' imgSrc={mobileChart} desktopImgSrc={desktopChart} noTitle showNote imgAlt=''>
                    </MobileChart>
                </MobileOnly>
                </div>
                <p>Mechanism of action: LENVIMA</p>
                <p>LENVIMA is thought to block receptors required for tumor growth and blood vessel development.</p>
                <Ul>
                    <li>LENVIMA is a kinase inhibitor that inhibits the kinase activities of VEGF receptors VEGFR1 (FLT1), VEGFR2 (KDR), and VEGFR3 (FLT4).</li>
                    <li>LENVIMA also inhibits other kinases (including FGF receptors FGFR1, 2, 3, and 4; PDGFRA; KIT; and RET) that have been implicated in pathogenic angiogenesis, tumor growth, and cancer progression, <b>in addition to their normal cellular functions.</b></li>
                </Ul>
            </Dosing2ColumnStyled>
            <StyledSubsection>
            <Subsection>
                    <RccFootnote>
                        <Paragraph>FGF = fibroblast growth factor; FGFR = fibroblast growth factor receptor; FLT = Fms-related tyrosine kinase; KDR = kinase insert domain receptor; KIT = receptor tyrosine kinase; MHC = major histocompatibility complex; <TextNoWrap>PD-1</TextNoWrap> = programmed death receptor-1; PDGFRA = platelet-derived growth factor receptor alpha; PD⁠-⁠L1 = programmed death ligand 1; <TextNoWrap>PD-L2</TextNoWrap> = programmed death ligand 2; RET = rearranged during transfection; TCR = T-cell receptor; TKI = tyrosine kinase inhibitor; VEGF = vascular endothelial growth factor; VEGFR1 (FLT1) = vascular endothelial growth factor receptor 1 (FLT1); VEGFR2 (KDR) = vascular endothelial growth factor receptor 2 (KDR); VEGFR3 (FLT4) = vascular endothelial growth factor receptor 3 (FLT4).</Paragraph>
                    </RccFootnote>
                </Subsection>
                </StyledSubsection>
            <RelatedContent title='You may also be interested in' links={RCLinks}/>
            <FootnotesSection noIntro>
                <FootnotesSubsection>
                    <Reference>
                        <b>References: 1.</b> Zuazo-Gaztelu I, Casanovas O. Unraveling the role of angiogenesis in cancer ecosystems. <i>Front Oncol</i>. 2018;8:248. doi:10.3389/fonc.2018.00248 <b>2.</b> Kato Y, Tabata K, Kimura T, et al. Lenvatinib plus anti-PD-1 antibody combination treatment activates CD8+ T cells through reduction of tumor-associated macrophage and activation of the interferon pathway. <i>PLoS ONE</i>. 2019;14(2):e0212513. doi:10.1371/journal.pone.0212513 <b>3.</b> Yamauchi M, Ono A, Amioka K, et al. Lenvatinib activates anti–tumor immunity by suppressing immunoinhibitory infiltrates in the tumor microenvironment of advanced hepatocellular carcinoma. <i>Commun Med (Lond)</i>. 2023;3(1):152. doi:10.1038/s43856-023-00390-x <b>4.</b> Kimura T, Kato Y, Ozawa Y, et al. Immunomodulatory activity of lenvatinib contributes to antitumor activity in the Hepa1-6 hepatocellular carcinoma model. <i>Cancer Sci</i>. 2018;109(12):3993–4002. doi:10.1111/cas.13806 <b>5.</b> Yi M, Jiao D, Qin S, et al. Synergistic effect of immune checkpoint blockade and anti-angiogenesis in cancer treatment. <i>Mol Cancer</i>. 2019;18(1):60. doi:10.1186/s12943-019-0974-6 <b>6.</b> Pardoll DM. The blockade of immune checkpoints in cancer immunotherapy. <i>Nat Rev Cancer</i>. 2012;12(4):252–264. doi:10.1038/nrc3239 <b>7.</b> Kudo M. Lenvatinib may drastically change the treatment landscape of hepatocellular carcinoma. <i>Liver Cancer</i>. 2018;7(1):1–19. doi:10.1159/000487148 
                    </Reference>
                </FootnotesSubsection>
            </FootnotesSection>
        </DefaultLayout>
    )
};
export default MechanismsOfAction;
